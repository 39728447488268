import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import navConfig1 from './MenuConfigMobile';
import DesktopMenuNew from './DesktopMenuNew';
import { MegaMenuDesktopHorizon, MegaMenuMobile, MenuConfig } from 'src/components/mega-menu';
import LOGO from '../../images/skyniche-logo.png'
import ChristmasLogo from '../../images/skyniche-christmas.gif'
import Santa from '../../images/santa-crosss.gif'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;
const APP_BAR_DESKTOP_SMALL = 52;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_DESKTOP,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  },
}));
const ToolbarStyle1 = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_DESKTOP_SMALL,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP_SMALL
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));
const SantaWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute', // Keeping it absolute as required
  bottom: '0px',
  left: '0px',
  width: '200px',
  height: 'auto',
  zIndex: 999,
  display: 'block',
  overflow: 'hidden', // Prevent overflow from the GIF container

  // Define the animation keyframes for a diagonal movement
  '@keyframes moveSanta': {
    '0%': {
      transform: 'translate3d(-100px, 0, 0)', // Start off-screen to the left
      visibility: 'hidden', // Hide during initial frame
    },
    '1%': {
      visibility: 'visible', // Make it visible immediately after the first frame
    },
    '100%': {
      transform: 'translate3d(calc(100vw - 200px), calc(-100vh + 200px), 0)', // Stay within bounds to prevent overflow
    }
  },

  // Apply the animation
  animation: 'moveSanta 20s linear infinite',

  // Hide the GIF on smaller screens
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },

  // Prevent horizontal overflow that could trigger scrollbars
  '& > *': {
    width: '100%',
    height: '100%',
    overflow: 'hidden', // Ensure content inside is clipped, not causing overflow
  },

  // Hide horizontal scrollbar globally
  overflowX: 'hidden', // Hide horizontal scrollbar in the parent wrapper (SantaWrapper)

  // Make sure it doesn’t overflow horizontally
  maxWidth: '100vw', // Ensure it doesn’t overflow horizontally
  left: 0, // Start from the left side
  right: 0, // Ensure it doesn’t overflow beyond the right side

  // Prevent layout shift or flicker during animation
  willChange: 'transform',
  margin: '0',
  padding: '0',
  boxSizing: 'border-box',
}));


// const SantaWrapper = styled(Box)(({ theme }) => ({
//   position: 'fixed',
//   top: '-8px', // Default top position
//   left: '-200px', // Start from the left side (off-screen)
//   width: '200px', // Set the width of the GIF
//   height: 'auto',
//   zIndex: 9999999,
//   display:'block',
//   overflow: 'hidden', // Hide anything outside of the container
//   '@keyframes moveSanta': {
//     '0%': {
//       transform: 'translateX(-200px)', // Start off-screen on the left side
//     },
//     '100%': {
//       transform: 'translateX(150vw)', // Move to the right side completely
//     }
//   },
//   animation: 'moveSanta 12s linear infinite', // Speed up the animation (12s instead of 20s)
  
//   [theme.breakpoints.down('md')]: {
//     display:'none'
//   },
// }));
// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const BlogInnerNew = pathname === '/empowering-business-transformation-with-ai';
  const NewBlogInnerNew = pathname === '/ai-driven-innovation-automation-and-market-transformation';
  const isEventsInner = /^\/events\/.+$/.test(pathname);

  return (
    <>
    <AppBar sx={{ boxShadow: 0,height:'40px',backgroundColor:'#e8e8e6',display:{xs:'none',sm:'none',md:'block'},position: BlogInnerNew ? 'static' : NewBlogInnerNew ? 'static' : isEventsInner ? 'static' :''  }}>
      <ToolbarStyle1
        disableGutters
        
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop:'-25px',
            justifyContent: 'flex-end',
            
          }}
        >

          <MHidden width="mdDown">
            <DesktopMenuNew isOffset={isOffset} isHome={isHome} />
          </MHidden>
        </Container>
      </ToolbarStyle1>

    </AppBar>
    
    {!BlogInnerNew ? !isEventsInner && !NewBlogInnerNew && (
      <AppBar color={'default'} sx={{ boxShadow: 0,marginTop:{xs:'0px',sm:'0px',md:'40px' }}}>
    {/* <AppBar color={isHome ? 'transparent' : 'default'} sx={{ boxShadow: 0 }}> */}
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            // height: { md: APP_BAR_DESKTOP - 10 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
          {/* <img 
            src={ChristmasLogo} 
            alt='Logo' 
            style={{ 
              cursor: 'pointer', 
              height: '75px', 
              marginTop: '-1px', 
              width: '245px',
              '@media (max-width: 957px)': {
                height: '65px',
                width: '215px'
              }
            }} 
          /> */}
          <img className='logo-responsive' src={LOGO} style={{cursor:'pointer'}} alt='Logo' />
          {/* <img className='logo-responsive-christmas' src={ChristmasLogo} style={{cursor:'pointer'}} alt='Logo' />  Not needed */}

          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            {/* <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} /> */}
            <MegaMenuDesktopHorizon navConfig={MenuConfig} />
          </MHidden>

          <MHidden width="mdUp">
            <MegaMenuMobile isOffset={isOffset} isHome={isHome} navConfig1={navConfig1} />
            {/* <MegaMenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} /> */}
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  ) : ""}
  {/* Add the Santa Video Animation */}
      {/* {isHome && (<SantaWrapper>
        <img 
          src={Santa} // Replace with the GIF path
          style={{ width: '200px', height: 'auto', transform: 'scaleX(-1)',}} 
          alt="Santa GIF"
        />
      </SantaWrapper>)}  */}

      {/* Future developer this is santa travelling in reindeer animation nothing to do just uncomment and see the magic happy christmas 🎅 from Deeraj 🤗*/}
    </>
  );
}